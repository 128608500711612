import React, { useCallback, useContext, useState, ReactNode, useRef, useMemo, useEffect } from 'react';
import { createURL } from '@sm/utils';
import { defineMessages, t } from '@sm/intl';
import { MetricsTracker, USER_EVENTS, generateMetricsAttribute } from '@sm/metrics';
import { Button } from '@wds/button';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { Input } from '@wds/input';
import { Checkbox } from '@wds/checkbox';
import { StaticContext } from '@sm/webassets/StaticContext';
import { Recaptcha, useRecaptcha } from '@sm/webassets/Recaptcha';
import classnames from 'classnames';
import useSignUpValidation from '~app/hooks/useSignUpValidation';
import { HeroFormCTAProps } from '~helpers/types';
import {
  ENDPAGE_APP_TYPE,
  ENDPAGE_ERROR_TYPE,
  ENDPAGE_EVENTS,
  ENDPAGE_INTERACTION_LOCATION,
  ENDPAGE_INTERACTION_TYPE,
} from '~app/components/amplitudeEnums';

import useStyles from './useStyles';
import ThirdPartySignUp from './ThirdPartySignUp';

const COPY = defineMessages({
  HERO_FORM_SOCIAL_CTA_COPY: {
    id: 'HeroFormCTA.SocialSignUp',
    defaultMessage: 'Or sign in with',
    description: '[Type: Label][Vis: med] - Informs the viewer they can sign in with social media',
  },
  VALIDATION_ERROR_EMAIL_TOO_LONG: {
    id: 'HeroFormCTA.EmailTooLong',
    defaultMessage: 'Sorry, email addresses must be 50 characters or less.',
    description: '[Type: Error][Vis: med] - Error message for email address that is too long',
  },
  VALIDATION_ERROR_USERNAME_TOO_LONG: {
    id: 'HeroFormCTA.UsernameTooLong',
    defaultMessage: 'Sorry, email addresses must be 50 characters or less.',
    description: '[Type: Error][Vis: med] - Error message for email address that is too long (same error as email)',
  },
  VALIDATION_ERROR_EMAIL_TOO_SHORT: {
    id: 'HeroFormCTA.EmailTooShort',
    defaultMessage: 'Please make your email address at least 3 characters long.',
    description: '[Type: Error][Vis: med] - Error message for email address that is too short',
  },
  VALIDATION_ERROR_USERNAME_TOO_SHORT: {
    id: 'HeroFormCTA.UsernameTooShort',
    defaultMessage: 'Please make your email address at least 3 characters long.',
    description: '[Type: Error][Vis: med] - Error message for email address that is too short (same error as email)',
  },
  VALIDATION_ERROR_EMAIL_MALFORMED: {
    id: 'HeroFormCTA.EmailMalformed',
    defaultMessage: 'Please enter a valid email address.',
    description: '[Type: Error][Vis: med] - Error message for email address that is malformed',
  },
  VALIDATION_ERROR_EMAIL_ROLE_BASED: {
    id: 'HeroFormCTA.EmailRoleBased',
    defaultMessage: "We can't deliver to this email address. Try a different one.",
    description: '[Type: Error][Vis: med] - Error message for role-based email address',
  },
  VALIDATION_ERROR_EMAIL_MISENCODED: {
    id: 'HeroFormCTA.EmailMisencoded',
    defaultMessage: 'Your email address is not valid.',
    description: '[Type: Error][Vis: med] - Error message for misencoded email address',
  },
  VALIDATION_ERROR_USERNAME_TAKEN: {
    id: 'HeroFormCTA.UsernameTaken',
    defaultMessage: 'Sorry, that email is already taken.',
    description: '[Type: Error][Vis: med] - Error message for email address that is already taken',
  },
  VALIDATION_ERROR_USERNAME_WHITESPACE_NOT_ALLOWED: {
    id: 'HeroFormCTA.UsernameWhitespaceNotAllowed',
    defaultMessage: "Sorry, we don't allow spaces.",
    description: '[Type: Error][Vis: med] - Error message for email address that contains whitespace',
  },
  VALIDATION_ERROR_USERNAME_CONTAINS_URL: {
    id: 'HeroFormCTA.UsernameContainsUrl',
    defaultMessage: "Email addresses can't include a URL.",
    description: '[Type: Error][Vis: med] - Error message for email address that contains a URL',
  },
  VALIDATION_ERROR_PASSWORD_TOO_SHORT: {
    id: 'HeroFormCTA.PasswordTooShort',
    defaultMessage: 'Please use at least 8 characters.',
    description: '[Type: Error][Vis: med] - Error message for password that is too short',
  },
  VALIDATION_ERROR_PASSWORD_IN_PROHIBITED_LIST: {
    id: 'HeroFormCTA.PasswordInProhibitedList',
    defaultMessage: 'Common words are easy to guess. Try again.',
    description: '[Type: Error][Vis: med] - Error message for password that is in a prohibited list',
  },
  VALIDATION_ERROR_PASSWORD_DOES_NOT_MATCH: {
    id: 'HeroFormCTA.PasswordDoesNotMatch',
    defaultMessage: "Passwords don't appear to match.",
    description: '[Type: Error][Vis: med] - Error message for password that does not match',
  },
  VALIDATION_ERROR_EMAIL_PASSWORD_SAME: {
    id: 'HeroFormCTA.EmailPasswordSame',
    defaultMessage: `Please use a password that's different from your email.`,
    description: '[Type: Error][Vis: med] - Error message for email and password that are the same',
  },
  VALIDATION_USERSVC_ERROR: {
    id: 'HeroFormCTA.UsersvcError',
    defaultMessage: 'Sorry, something went wrong. Please try again.',
    description: '[Type: Error][Vis: med] - Generic error message for if validation fails',
  },
  VALIDATION_NETWORK_ERROR: {
    id: 'HeroFormCTA.NetworkError',
    defaultMessage: 'Sorry, something went wrong. Please try again.',
    description: '[Type: Error][Vis: med] - Generic error message for if validation fails due to a network error',
  },
});

// this is only ever shown in german, so we'll leave it outside of l10n for now
// anonweb did it this way, so there's precedence
const DE_CHECKBOX_ERROR = {
  id: 'HeroFormCTA.DECheckboxError',
  defaultMessage: 'Sie müssen zuerst die oben markierte Checkbox auswählen.',
  description: '[Type: Error][Vis: med] - Error message when terms are not accepted',
};

/** Type-wrapper for `Typography` to allow use of `dangerouslySetInnerHTML`  */
const TypographyWithInnerHTML = Typography as React.ForwardRefExoticComponent<
  Omit<React.ComponentProps<typeof Typography>, 'children'> & {
    dangerouslySetInnerHTML: { __html: string };
    children?: never;
  }
>;

const getValidationError = (error?: string): string => {
  if (error) {
    // if we don't have a specific error message, default to a generic network error
    const errorKey =
      COPY[`VALIDATION_ERROR_${error.toUpperCase()}` as keyof typeof COPY] ?? COPY.VALIDATION_NETWORK_ERROR;
    return t(errorKey);
  }
  return '';
};

const HeroFormCTA = ({
  className,
  ctaFormEmail,
  ctaFormEmailPlaceholder,
  ctaFormNewsletterOptOut,
  ctaFormPassword,
  ctaFormPasswordPlaceholder,
  ctaFormPrivacyConsent,
  ctaFormSubmit,
  disclaimer,
  utSource = '',
  utSource2 = '',
  boldHeader,
  subHeader,
  showThirdPartySignUpActions = true,
  showDisclaimer = true,
  isEnterpriseThankYou,
}: HeroFormCTAProps): React.ReactElement => {
  const utSource3 = 'HeroFormCTA';
  const isExperiment = utSource2 === 'RebrandEpFormV1';
  const formRef = useRef<HTMLFormElement>(null);
  // we need references to the two inputs as <Input> doesn't support focusin for firing
  // the focused event...
  const emailInputRef = useRef<HTMLInputElement>(null);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const privacyCheckRef = useRef<HTMLInputElement>(null);

  const {
    environment: { countryCode },
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  const { enterpriseThankYouForm, ctaFormField, ctaLabel, visuallyHidden, experimentSpan, disclaimerContainer } =
    useStyles({
      isEnterpriseThankYou,
      isExperiment,
    });

  const containerClasses = classnames('sm-hero-form-cta', className);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const {
    hasEnteredEmail,
    hasEnteredPassword,
    isValid,
    isLoading: isValidating,
    emailError,
    passwordError,
    setEmail: setValidatorEmail,
    setPassword: setValidatorPassword,
  } = useSignUpValidation();
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const recaptchaContext = useRecaptcha({
    actionName: 'user_signup',
    successCallback: undefined, // should not use for DOM manipulations
    siteKey: undefined, // set via StaticContext
    useEnterprise: undefined, // set via StaticContext
    errorCallback: undefined,
  });

  /**
   * Fires an Amplitude "endpage user interaction" event
   *
   * @param interactionType The type of interaction that's happening
   * @param interactionLocation Where the interaction is happening
   */
  const fireInteractionEvent = useCallback(
    (interactionType: ENDPAGE_INTERACTION_TYPE, interactionLocation: ENDPAGE_INTERACTION_LOCATION): void => {
      MetricsTracker.track({
        name: USER_EVENTS.ELEMENT_INTERACTION,
        data: {
          amplitudeEvent: ENDPAGE_EVENTS.USER_INTERACTION,
          interactionLocation,
          interactionType,
          templateType,
          app: ENDPAGE_APP_TYPE.RESPWEB,
        },
      });
    },
    [templateType]
  );

  useEffect(() => {
    // email/password event handlers so we can send the focus Amplitude event once
    const currentEmailRef = emailInputRef?.current;
    const handleEmailFocusIn = (): void => {
      fireInteractionEvent(ENDPAGE_INTERACTION_TYPE.FOCUSED, ENDPAGE_INTERACTION_LOCATION.EMAIL_INPUT);
    };
    currentEmailRef?.addEventListener('focusin', handleEmailFocusIn, { once: true });

    const currentPasswordRef = passwordInputRef?.current;
    const handlePasswordFocusIn = (): void => {
      fireInteractionEvent(ENDPAGE_INTERACTION_TYPE.FOCUSED, ENDPAGE_INTERACTION_LOCATION.PASSWORD_INPUT);
    };
    currentPasswordRef?.addEventListener('focusin', handlePasswordFocusIn, { once: true });
    return (): void => {
      currentEmailRef?.removeEventListener('focusin', handleEmailFocusIn);
      currentPasswordRef?.removeEventListener('focusin', handlePasswordFocusIn);
    };
  }, [emailInputRef, fireInteractionEvent, passwordInputRef]);

  const handlePasswordChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      // only fire the event the first time the user enters a character
      if (!hasEnteredPassword && !password) {
        fireInteractionEvent(ENDPAGE_INTERACTION_TYPE.ENTERED_TEXT, ENDPAGE_INTERACTION_LOCATION.PASSWORD_INPUT);
      }
      setPassword(e.target.value);
    },
    [fireInteractionEvent, hasEnteredPassword, password, setPassword]
  );

  const handleEmailChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    e => {
      // only fire the event the first time the user enters a character
      if (!hasEnteredEmail && !email) {
        fireInteractionEvent(ENDPAGE_INTERACTION_TYPE.ENTERED_TEXT, ENDPAGE_INTERACTION_LOCATION.EMAIL_INPUT);
      }
      setEmail(e.target.value);
    },
    [email, fireInteractionEvent, hasEnteredEmail, setEmail]
  );

  const handleFormSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    evt => {
      evt.preventDefault();
      // kick off one last wave of re-validation. this will catch a user pressing
      // enter to submit the form or if something managed to sneak by before
      setValidatorEmail(email);
      setValidatorPassword(password);

      // raise the submit flag so we can begin evaluating the validation status
      setShouldSubmit(true);
    },
    [email, password, setShouldSubmit, setValidatorEmail, setValidatorPassword]
  );

  useMemo(() => {
    if (
      // if the form isn't trying to be submitted and this was run due to a
      // re-render triggered by another dependency, bail
      !shouldSubmit ||
      // the form is validating, wait regardless of what it's last valid state was
      isValidating
    ) {
      return;
    }

    // the form is valid and is not waiting for further validation, so begin actual submission
    // via reCAPTCHA (it'll do its thing and then get picked up below)
    if (isValid) {
      recaptchaContext.fireGRE();
      return;
    }

    // the form isn't valid and it's not validating, we shouldn't be trying to submit
    setShouldSubmit(false);
  }, [isValid, isValidating, recaptchaContext, shouldSubmit, setShouldSubmit]);

  // submits the form action when reCAPTCHA sets the token properly
  useMemo(() => {
    if (recaptchaContext.greToken.length && formRef && shouldSubmit) {
      // async the form submit to ensure that react has enough time
      // to re-render the form with the token input set
      setTimeout(() => {
        // one last check to make sure that nothing changed with the validation
        // before actually submitting the form
        if (isValidating || !isValid || !shouldSubmit || isSubmitting) {
          return;
        }

        fireInteractionEvent(ENDPAGE_INTERACTION_TYPE.SUBMITTED, ENDPAGE_INTERACTION_LOCATION.SIGNUP_FORM);
        MetricsTracker.track({
          name: USER_EVENTS.ELEMENT_INTERACTION,
          data: {
            amplitudeEvent: ENDPAGE_EVENTS.USER_INTERACTION,
            interactionLocation: ENDPAGE_INTERACTION_LOCATION.SIGNUP_FORM,
            interactionType: ENDPAGE_INTERACTION_TYPE.SUBMITTED,
            app: ENDPAGE_APP_TYPE.RESPWEB,
          },
        });
        setIsSubmitting(true);
        formRef.current?.submit();
      }, 0);
    }
  }, [
    fireInteractionEvent,
    recaptchaContext.greToken,
    isValidating,
    isValid,
    isSubmitting,
    shouldSubmit,
    setIsSubmitting,
  ]);

  // fires Amplitude validation error events when email/password errors crop up.
  // this is split between two different hooks so we're not potentially firing
  // the same event twice
  useMemo(() => {
    if (emailError) {
      MetricsTracker.track({
        name: USER_EVENTS.ELEMENT_INTERACTION,
        data: {
          amplitudeEvent: ENDPAGE_EVENTS.ERRORED,
          errorType: ENDPAGE_ERROR_TYPE.FORM_VALIDATION,
          errorInformation: emailError,
          templateType,
          app: ENDPAGE_APP_TYPE.RESPWEB,
        },
      });
    }
  }, [emailError, templateType]);

  useMemo(() => {
    if (passwordError) {
      MetricsTracker.track({
        name: USER_EVENTS.ELEMENT_INTERACTION,
        data: {
          amplitudeEvent: ENDPAGE_EVENTS.ERRORED,
          errorType: ENDPAGE_ERROR_TYPE.FORM_VALIDATION,
          errorInformation: passwordError,
          templateType,
          app: ENDPAGE_APP_TYPE.RESPWEB,
        },
      });
    }
  }, [passwordError, templateType]);

  const signUpPath = '/login/api/v1/quick-sign-up';

  const isGermanLang = useMemo<boolean>(() => countryCode.toLowerCase() === 'de', [countryCode]);
  const disableFields = useMemo(() => shouldSubmit || isSubmitting, [shouldSubmit, isSubmitting]);

  return (
    <Box className={containerClasses} pt={7} data-testid="HeroFormCTA">
      {isExperiment ? (
        <Box>
          <Typography component="section" variant="sectionTitleSm" weight="medium" align="center">
            <span className={experimentSpan}>{boldHeader}</span>
          </Typography>
          <Box pt={3} pb={5}>
            <Typography component="div" variant="bodySm" align="center">
              {subHeader}
            </Typography>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <form
        id="signup_form"
        method="post"
        action={createURL(signUpPath, {
          origin: 'surveyendpage',
          ut_source: utSource,
          ut_source2: utSource2,
          ut_source3: utSource3,
          ut_ctatext: ctaFormSubmit,
        })}
        className={isEnterpriseThankYou ? enterpriseThankYouForm : undefined}
        data-testid="HeroFormCTA__Form"
        data-sm-metrics={generateMetricsAttribute({
          data: {
            amplitudeEvent: ENDPAGE_EVENTS.USER_INTERACTION,
            itemSelected: 'sign-up primary',
            templateType,
            surveyId,
            collectorId,
            respondentId,
            log: {
              message: `${utSource}-${utSource2}-${utSource3}-hero-form-sign-up`,
            },
          },
        })}
        onSubmit={handleFormSubmit}
        ref={formRef}
        /* disable browser based validation since we're going to handle that */
        noValidate
      >
        <Recaptcha
          greToken={recaptchaContext.greToken}
          showError={recaptchaContext.showError}
          setShowError={recaptchaContext.setShowError}
        />
        <input type="hidden" name="username" className="input-username" value={email} />
        <input type="hidden" name="password_confirm" className="input-password_confirm" value={password} />
        <Box element="div" className={ctaFormField}>
          <label className={isEnterpriseThankYou ? visuallyHidden : ctaLabel} htmlFor="email-input">
            <Typography component="span" variant="bodySm">
              {ctaFormEmail}
            </Typography>
          </label>
          <Input
            id="email-input"
            type="email"
            name="email"
            placeholder={ctaFormEmailPlaceholder}
            stretched
            onChange={handleEmailChange}
            value={email}
            // NOTE: if we ever change validation from blur to onChange, we'll need to
            // add some debouncing. currently, this operates on human speed debouncing
            onBlur={() => setValidatorEmail(email)}
            errorMessage={getValidationError(emailError)}
            color={emailError ? 'error' : undefined}
            ref={emailInputRef}
          />
        </Box>
        <Box element="div" className={ctaFormField}>
          <label className={isEnterpriseThankYou ? visuallyHidden : ctaLabel} htmlFor="password-input">
            <Typography component="span" variant="bodySm">
              {ctaFormPassword}
            </Typography>
          </label>
          <Input
            id="password-input"
            type="password"
            name="password"
            ariaLabel={ctaFormPassword}
            stretched
            onChange={handlePasswordChange}
            placeholder={ctaFormPasswordPlaceholder}
            value={password}
            // NOTE: if we ever change validation from blur to onChange, we'll need to
            // add some debouncing. currently, this operates on human speed debouncing
            onBlur={() => setValidatorPassword(password)}
            errorMessage={getValidationError(passwordError)}
            color={passwordError ? 'error' : undefined}
            ref={passwordInputRef}
          />
        </Box>
        {isGermanLang && (
          <>
            <Box element="div" className={ctaFormField}>
              <Checkbox
                // weird required type from Wrench due to merge of HTMLAttribute and custom type
                // ((boolean | ReactChild | ReactFragment | ReactPortal | null) & string) | undefined
                label={ctaFormPrivacyConsent as unknown as ReactNode & string}
                id="privacy-consents"
                name="privacy-consents"
                innerRef={privacyCheckRef}
                onChange={evt => setHasAcceptedTerms((evt.target as HTMLInputElement).checked)}
                required
              />
              {!hasAcceptedTerms && <Typography color="error">{t(DE_CHECKBOX_ERROR)}</Typography>}
            </Box>
            <Box element="div" className={ctaFormField}>
              <Checkbox label={ctaFormNewsletterOptOut} id="subscribe-to-newsletter" name="subscribe-to-newsletter" />
            </Box>
          </>
        )}
        <Button
          size="lg"
          stretched
          onClick={recaptchaContext.fireGRE}
          disabled={disableFields}
          buttonType="submit"
          loading={shouldSubmit || isSubmitting}
        >
          {ctaFormSubmit}
        </Button>
      </form>
      {showThirdPartySignUpActions && (
        <ThirdPartySignUp
          utSource={utSource}
          utSource2={utSource2}
          templateType={templateType}
          surveyId={surveyId}
          collectorId={collectorId}
          respondentId={respondentId}
          bannerText={t(COPY.HERO_FORM_SOCIAL_CTA_COPY)}
        />
      )}
      {showDisclaimer && (
        <Box mt={5} className={disclaimerContainer}>
          <TypographyWithInnerHTML
            align="center"
            component="div"
            variant="bodySm"
            color="darkMuted"
            dangerouslySetInnerHTML={{ __html: `${disclaimer}` }} // eslint-disable-line jam3/no-sanitizer-with-danger
          />
        </Box>
      )}
    </Box>
  );
};

export default HeroFormCTA;
